.swiper-button-next,
.swiper-button-prev {
    color: white !important;
}

.swiper-pagination-bullet {
    background: white !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
}