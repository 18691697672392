.menu-item a {
    border-bottom: 2px solid transparent;
    border-radius: 0 !important;
}

.menu-item a:hover {
    background: none;
    border-bottom: 2px solid #2563eb;
}

.menu-item a:focus {
    background: none;
    color: #2563eb;
}

.dropdown-item:hover {
    background: #2563eb !important;
    color: white;
}